import React, { useState, useEffect } from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './Components/Header';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';

// Import all the page components
import Home from './Pages/Home';
import Ourapp from './Pages/Ourapp';
import Research from './Pages/Research';
import Indicators from './Pages/Indicators';
import Collaboration from './Pages/Collaboration';
import About from './Pages/About';
import Indicator1 from './Pages/Indicator1';
import Indicator2 from './Pages/Indicator2';
import Indicator3 from './Pages/Indicator3';
import Indicator4 from './Pages/Indicator4';
import Indicator5 from './Pages/Indicator5';
import Indicator6 from './Pages/Indicator6';
import Footer from './Components/Footer';
import Disclaimer from './Pages/Disclaimer';
import Privacy from './Pages/Privacy';
import Manifesto from './Pages/Manifesto';
import Terms from './Pages/Terms';
import Support from './Pages/Support';

import SplashScreen from './Components/SplashScreen'; 
import HomeBackgroundVideo from './Components/Home Page 1 (Blank).mp4'; // Background video for Home

function AppContent() {
  const location = useLocation();

  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={<Ourapp />} />
        <Route path="/indicators" element={<Indicators />} />
        <Route path="/research" element={<Research />} />
        <Route path="/contact" element={<Collaboration />} />
        <Route path="/about" element={<About />} />
        {/* Indicator Routes */}
        <Route path="/indicators/Bullish" element={<Indicator1 />} />
        <Route path="/indicators/Volume" element={<Indicator2 />} />
        <Route path="/indicators/Session" element={<Indicator3 />} />
        <Route path="/indicators/Metrics" element={<Indicator4 />} />
        <Route path="/indicators/EMAvol" element={<Indicator5 />} />
        <Route path="/indicators/AdaptiveEMA" element={<Indicator6 />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/manifesto" element={<Manifesto />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/support" element={<Support />} />
      </Routes>
      {location.pathname !== '/contact' && <Footer />}
    </>
  );
}

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true); // Track splash screen state

  const saveScrollPosition = () => {
    const scrollY = window.scrollY;
    localStorage.setItem('scrollPosition', scrollY); // Save scroll position to localStorage
  };

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    
    if (hasVisited) {
      setIsSplashVisible(false); // Skip the splash screen if visited before
    } else {
      localStorage.setItem('hasVisited', 'true'); // Mark as visited
    }

    // Disable browser's automatic scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition !== null) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10)); // Restore saved scroll position
    }

    window.addEventListener('scroll', saveScrollPosition);
    return () => {
      window.removeEventListener('scroll', saveScrollPosition);
    };
  }, []);

  const handleVideoEnd = () => {
    setIsSplashVisible(false);
  };

  return (
    <>
      {isSplashVisible && <SplashScreen onVideoEnd={handleVideoEnd} />}
      <video
        src={HomeBackgroundVideo}
        style={{ display: 'none' }}
        onCanPlayThrough={handleVideoEnd}
      ></video>

      {!isSplashVisible && (
        <Router>
          <AppContent />
        </Router>
      )}
    </>
  );
}

export default App;
