// src/Pages/Indicators.js

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Indicators.css';

// Import images
import Ind1 from '../Components/L1-3 - Ind1.png';
import Ind2 from '../Components/L1-3 - Ind2.png';
import Ind3 from '../Components/L1-3 - Ind3.png';
import Ind4 from '../Components/L1-3 - Ind4.png';
import Ind5 from '../Components/L1-3 - Ind5.png';
import Ind6 from '../Components/L1-3 - Ind6.png';

// Import the background video
import BackgroundVideo from '../Components/L2 - Ind - Bkg (v11).mp4';

export default function Indicators() {
  const contentRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Ensure the video plays once and then stops
    video.loop = false;

    // Start a timer to show the content and then blur the video
    const timer = setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.classList.add('visible');
      }
      // Once the content is visible, blur the background video
      if (video) {
        video.classList.add('blurred');
      }
    }, 1750);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="indicators-page">
      {/* Video Background */}
      <div className="video-background2">
        <video ref={videoRef} className="background-video2" autoPlay muted>
          <source src={BackgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Content Container */}
      <div className="content-container" ref={contentRef}>
        {/* Title Text */}
        <h1 className="title-text">TRADING VIEW INDICATORS</h1>

        {/* Gradient Horizontal Line */}
        <div className="gradient-line"></div>

        {/* Image Grid */}
        <div className="image-grid">
          <div className="grid-item">
            <Link to="/indicators/Bullish">
              <img src={Ind1} alt="Bullish/Bearish Volume" loading="lazy" />
              <p className="indicator-text">Bullish/Bearish Volume</p>
            </Link>
          </div>
          <div className="grid-item">
            <Link to="/indicators/Volume">
              <img src={Ind2} alt="Volume Profile" loading="lazy" />
              <p className="indicator-text">Volume Profile</p>
            </Link>
          </div>
          <div className="grid-item">
            <Link to="/indicators/Session">
              <img src={Ind3} alt="Forex Session Tracker" loading="lazy" />
              <p className="indicator-text">Forex Session Tracker</p>
            </Link>
          </div>
          <div className="grid-item">
            <Link to="/indicators/Metrics">
              <img src={Ind4} alt="Forex Macro Metrics" loading="lazy" />
              <p className="indicator-text">Forex Macro Metrics</p>
            </Link>
          </div>
          <div className="grid-item">
            <Link to="/indicators/EMAvol">
              <img src={Ind5} alt="EMA Volume" loading="lazy" />
              <p className="indicator-text">EMA Volume</p>
            </Link>
          </div>
          <div className="grid-item">
            <Link to="/indicators/AdaptiveEMA">
              <img src={Ind6} alt="Adaptive Smooth EMA" loading="lazy" />
              <p className="indicator-text">Adaptive Smooth EMA</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
