// src/Components/CarouselItem.js
import React from 'react';
import { Link } from 'react-router-dom';

export default function CarouselItem({ image }) {
  return (
    <div className="carousel-card">
      <div className="carousel-label">{image.label}</div>
      <Link to={image.link}>
        <img src={image.src} alt={image.alt} />
      </Link>
      {/* Button removed as per requirement */}
    </div>
  );
}
