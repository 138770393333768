import React, { useRef, useEffect } from 'react';
import './Research1.css';

// Import your research images
import ResScr1 from './L1 - Res - Scr1.png';
import ResScr2 from './L1 - Res - Scr2.png';
import ResScr3 from './L1 - Res - Scr3.png';
import ResScr4 from './L1 - Res - Scr4.png';
import ResScr5 from './L1 - Res - Scr5.png';
import ResScr6 from './L1 - Res - Scr6.png';

export default function Research() {
  const sectionRef = useRef(null);
  const wrapperRefs = useRef([]);
  const fixedContentRef = useRef(null);
  const lineRef = useRef(null);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);

  // Track whether we've frozen final states at the bottom
  const frozenRef = useRef(false);

  useEffect(() => {
    const section = sectionRef.current;
    const fixedContent = fixedContentRef.current;
    const line = lineRef.current;
    const title = titleRef.current;
    const button = buttonRef.current;
    const wrappers = wrapperRefs.current;
    
    const totalImages = wrappers.length;
    // Each pair is 2 images => number of pairs:
    const pairsCount = Math.ceil(totalImages / 2);

    // We have 3 "items" (line, title, button) + N pairs
    const totalItems = 3 + pairsCount;

    // We'll do a "chunked" approach:
    // section height is 600vh if totalItems=6, i.e. each item chunk is 100vh
    // if you add items, adjust the section height in .css accordingly

    let lastScrollTop = 0;
    let ticking = false;

    function handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          animateSection(scrollTop);
          ticking = false;
        });
        ticking = true;
      }
    }

    function animateSection(scrollTop) {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight; // e.g. 600vh
      const windowHeight = window.innerHeight;
      const scrollPos = scrollTop - sectionTop;
      const totalScroll = sectionHeight - windowHeight;

      // If above the section
      if (scrollTop < sectionTop) {
        // Place content at top as absolute
        fixedContent.style.position = 'absolute';
        fixedContent.style.top = '0px';
        resetAll(); // Reset everything to initial
        frozenRef.current = false;
        return;
      }
      // If below the section
      if (scrollTop > sectionTop + sectionHeight - windowHeight) {
        // Place content at bottom as absolute
        fixedContent.style.position = 'absolute';
        fixedContent.style.top = (sectionHeight - windowHeight) + 'px';
        freezeFinal(); // Freeze everything
        return;
      }

      // Otherwise, we're inside the section
      fixedContent.style.display = 'block';
      fixedContent.style.position = 'fixed';
      fixedContent.style.top = '0px';

      const stopThreshold = totalScroll * 0.95;

      // Check freeze logic near bottom
      if (scrollPos > stopThreshold && !frozenRef.current) {
        // Freeze final states
        freezeFinal();
        fixedContent.style.position = 'absolute';
        fixedContent.style.top = scrollPos + 'px';
        return;
      }
      if (frozenRef.current && scrollPos <= stopThreshold) {
        // Unfreeze if we scroll up above threshold
        frozenRef.current = false;
        fixedContent.style.position = 'fixed';
        fixedContent.style.top = '0px';
      }

      if (frozenRef.current) {
        // If final states are frozen, no more anim
        return;
      }

      // Animate from 0→1 progress
      const progress = Math.min(Math.max(scrollPos / totalScroll, 0), 1);

      // For each item index in [0..(totalItems-1)], we consider a sub-range
      // so each item is triggered in its own chunk.
      // chunkHeight = totalScroll / totalItems
      // itemScrollStart = itemIndex * chunkHeight
      // itemScrollEnd = (itemIndex+1) * chunkHeight

      // line => itemIndex=0
      animateItem(line, 0, progress, totalItems);
      // title => itemIndex=1
      animateItem(title, 1, progress, totalItems);
      // button => itemIndex=2
      animateItem(button, 2, progress, totalItems);

      // Now animate pairs => itemIndex=3..(3+pairsCount-1)
      for (let pairIndex = 0; pairIndex < pairsCount; pairIndex++) {
        const wr1 = wrappers[2 * pairIndex];
        const wr2 = wrappers[2 * pairIndex + 1] || null;
        const itemIndex = 3 + pairIndex; // chunk for this pair
        animatePair(wr1, wr2, itemIndex, progress, totalItems, pairIndex);
      }
    }

    // Animate a single line/title/button item
    function animateItem(element, itemIndex, overallProgress, total) {
      if (!element) return;
      // chunkStart = itemIndex/total, chunkEnd = (itemIndex+1)/total
      const chunkStart = itemIndex / total;
      const chunkEnd = (itemIndex + 1) / total;
      const localProgress = (overallProgress - chunkStart) / (chunkEnd - chunkStart);

      // clamp to [0..1]
      const clamped = Math.min(Math.max(localProgress, 0), 1);

      // We do the same transform logic as your code:
      const translateRange = 50; 
      const translateY = (1 - clamped) * translateRange;
      const opacity = clamped;

      element.style.transform = `translate(-50%, ${translateY}vh)`;
      element.style.opacity = opacity;
    }

    // Animate a pair of images
    function animatePair(wr1, wr2, itemIndex, overallProgress, total, pairIndex) {
      if (!wr1) return;
      // chunkStart..chunkEnd
      const chunkStart = itemIndex / total;
      const chunkEnd = (itemIndex + 1) / total;
      const localProgress = (overallProgress - chunkStart) / (chunkEnd - chunkStart);
      const clamped = Math.min(Math.max(localProgress, 0), 1);

      const translateY = (1 - clamped) * 50;
      const rotateX = -15 + 15 * clamped; 
      const opacity = clamped;

      wr1.style.transform = `translateY(${translateY}vh) rotateX(${rotateX}deg)`;
      wr1.style.opacity = opacity;
      if (wr2) {
        wr2.style.transform = `translateY(${translateY}vh) rotateX(${rotateX}deg)`;
        wr2.style.opacity = opacity;
      }

      // ensure previous pairs fully visible if localProgress>0
      if (clamped > 0) {
        for (let p = 0; p < pairIndex; p++) {
          const pi1 = 2 * p;
          const pi2 = 2 * p + 1;
          if (wrapperRefs.current[pi1]) {
            wrapperRefs.current[pi1].style.transform = 'translateY(0vh) rotateX(0deg)';
            wrapperRefs.current[pi1].style.opacity = 1;
          }
          if (wrapperRefs.current[pi2]) {
            wrapperRefs.current[pi2].style.transform = 'translateY(0vh) rotateX(0deg)';
            wrapperRefs.current[pi2].style.opacity = 1;
          }
        }
      }
    }

    function freezeFinal() {
      // final states => everything visible
      if (!lineRef.current || !titleRef.current || !buttonRef.current) return;
      lineRef.current.style.transform = 'translate(-50%, 0vh)';
      lineRef.current.style.opacity = '1';
      titleRef.current.style.transform = 'translate(-50%, 0vh)';
      titleRef.current.style.opacity = '1';
      buttonRef.current.style.transform = 'translate(-50%, 0vh)';
      buttonRef.current.style.opacity = '1';
      wrappers.forEach(wr => {
        wr.style.transform = 'translateY(0vh) rotateX(0deg)';
        wr.style.opacity = 1;
      });
      frozenRef.current = true;
    }

    function resetAll() {
      // revert all to initial states
      if (!lineRef.current || !titleRef.current || !buttonRef.current) return;
      lineRef.current.style.transform = 'translate(-50%,50vh)';
      lineRef.current.style.opacity = '0';
      titleRef.current.style.transform = 'translate(-50%,50vh)';
      titleRef.current.style.opacity = '0';
      buttonRef.current.style.transform = 'translate(-50%,50vh)';
      buttonRef.current.style.opacity = '0';
      wrappers.forEach((wr) => {
        wr.style.transform = 'translateY(50vh) rotateX(-15deg)';
        wr.style.opacity = 0;
      });
    }

    // Setup scroll listener
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // initial run

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const images = [
    { src: ResScr1, alt: 'Research 1' },
    { src: ResScr2, alt: 'Research 2' },
    { src: ResScr3, alt: 'Research 3' },
    { src: ResScr4, alt: 'Research 4' },
    { src: ResScr5, alt: 'Research 5' },
    { src: ResScr6, alt: 'Research 6' },
  ];

  return (
    <div className="research1-section" ref={sectionRef}>
      <div className="fixed-content" ref={fixedContentRef}>
        <div className="gradient-line2" ref={lineRef}></div>
        <h1 className="research1-title" ref={titleRef}>
          RESEARCH & ARTICLES
        </h1>
        <a href="/research" className="explore-button2" ref={buttonRef}>
          Explore More
        </a>
        <div className="images-container">
          {images.map((image, index) => (
            <div
              key={index}
              className="research1-image-wrapper"
              ref={(el) => (wrapperRefs.current[index] = el)}
            >
              <img src={image.src} alt={image.alt} className="research1-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
