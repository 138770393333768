// src/Pages/Home.js

import React, { useRef, useEffect, useState } from "react";
import VideoBackground from "../Components/VideoBackground";
import HomeVideo from "../Components/L1 - Plnt (v6).mp4";
import './Home.css';
import anime from 'animejs/lib/anime.es.js';
import CarouselIndicators from '../Components/CarouselIndicators';
import Research from '../Components/Research1';
import ContactUs from '../Pages/ContactUs';
import Ft from '../Pages/Ft';
import { Link } from 'react-router-dom';

export default function Home() {
    const animationVideoRef = useRef(null);
    const appSectionRef = useRef(null);
    const aboutSectionRef = useRef(null);
    const indicatorsSectionRef = useRef(null);

    // Helper function to wrap each letter in a span
    const wrapTextWithSpans = (text) => {
        return text.split('').map((char, index) => {
            const className = char === ' ' ? 'letter space' : 'letter';
            return (
                <span key={index} className={className}>{char}</span>
            );
        });
    };

    // Initial top-content animations
    useEffect(() => {
        anime.timeline({ loop: false })
            .add({
                targets: '.main-text .line:nth-child(1)',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                delay: 300,
                duration: 1000
            })
            .add({
                targets: '.main-text .line:nth-child(2)',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 500
            })
            .add({
                targets: '.transforming-text',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 500
            })
            .add({
                targets: '.explore-button',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 500,
                delay: 200
            });
    }, []);    

    // About section animation when in view
    const [hasAboutAnimationPlayed, setHasAboutAnimationPlayed] = useState(false);
    useEffect(() => {
        let observer;
        const aboutSectionElement = aboutSectionRef.current;

        if (aboutSectionElement) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting && !hasAboutAnimationPlayed) {
                            console.log("About section is in view. Starting animations.");
                            anime.timeline({ loop: false })
                                .add({
                                    targets: '.new-main-text .letter',
                                    opacity: [0, 1],
                                    easing: "easeInOutQuad",
                                    duration: 1000,
                                    delay: (el, i) => 50 * (i + 1)
                                })
                                .add({
                                    targets: ['.mission-text', '.separator-line', '.paragraph'],
                                    opacity: [0, 1],
                                    translateX: ['-50px', '0px'],
                                    width: (el) => el.classList.contains('separator-line') ? ['0%', '100%'] : undefined,
                                    easing: "easeOutExpo",
                                    duration: 1000
                                });

                            setHasAboutAnimationPlayed(true);
                            observer.unobserve(aboutSectionElement);
                        }
                    });
                },
                { 
                    threshold: 0.3,
                    rootMargin: "0px 0px -25% 0px"
                }
            );
            observer.observe(aboutSectionElement);
        }

        return () => {
            if (observer && aboutSectionElement) {
                observer.unobserve(aboutSectionElement);
            }
        };
    }, [hasAboutAnimationPlayed]);

    // Indicators background reveal animation
    useEffect(() => {
        const sectionRef = indicatorsSectionRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
                        console.log("Triggering mask animation for background image.");
                        const maskElement = entry.target.querySelector('.indicators-bg');
                        if (maskElement) {
                            maskElement.classList.add('reveal');
                        }
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.4 }
        );

        if (sectionRef) {
            observer.observe(sectionRef);
        }

        return () => {
            if (sectionRef) {
                observer.unobserve(sectionRef);
            }
        };
    }, []);

    // Smooth scrolling effect for "Explore Horizons"
    useEffect(() => {
        const anchors = document.querySelectorAll('a[href^="#"]');
        const handleClick = function (e) {
            e.preventDefault();
            const target = document.querySelector(this.getAttribute('href'));
            if (target) {
                target.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        };

        anchors.forEach(anchor => {
            anchor.addEventListener('click', handleClick);
        });

        return () => {
            anchors.forEach(anchor => {
                anchor.removeEventListener('click', handleClick);
            });
        };
    }, []);

    // Indicators mask animation
    useEffect(() => {
        const indicatorsSection = indicatorsSectionRef.current;

        if (indicatorsSection) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            console.log("Indicators section is visible.");
                            const mask = entry.target.querySelector('.indicators-mask');
                            if (mask) {
                                mask.style.animationPlayState = 'running'; // Start the mask animation
                            }
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { threshold: 0.4 }
            );

            observer.observe(indicatorsSection);
            return () => observer.disconnect();
        }
    }, []);  

    // Add .visible class to .app-section on scroll
    useEffect(() => {
        const handleScroll = () => {
            if (appSectionRef.current) {
                const rect = appSectionRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight * 0.3) {
                    appSectionRef.current.classList.add('visible');
                }
            }
        };

        // Check immediately
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {/* Video Section */}
            <div className="video-section">
                <VideoBackground videoSrc={HomeVideo} customClass="home-background" />
                <div className="home-content top-content">
                    <h1 className="main-text">
                        <span className="line">{wrapTextWithSpans("YOUR EASY WAY")}</span>
                        <span className="line">{wrapTextWithSpans("TO FOLLOW MARKET TRENDS")}</span>
                    </h1>
                    <p className="sub-text transforming-text">
                        <span className="line">
                            <span className="gradient-transforming">{wrapTextWithSpans("TRANSFORMING")}</span>
                            <span className="white-text">{wrapTextWithSpans(" THE ")}</span>
                            <span className="gradient-chaos">{wrapTextWithSpans("CHAOS")}</span>
                            <span className="white-text">{wrapTextWithSpans(" OF THE MARKETS")}</span>
                        </span>
                        <span className="line">
                            <span className="white-text">{wrapTextWithSpans("INTO ")}</span>
                            <span className="gradient-opportunities">{wrapTextWithSpans("OPPORTUNITIES")}</span>
                        </span>
                    </p>

                    <a href="#about" className="explore-button">Explore Horizons</a>
                </div>

                {/* About Section */}
                <div id="about" className="home-content bottom-content" ref={aboutSectionRef}>
                    <h1 className="new-main-text">
                        <span className="line">{wrapTextWithSpans("At MacroGlide")}</span>
                    </h1>
                    <p className="new-sub-text mission-text">
                        <span className="line">
                            <span className="white-text">{wrapTextWithSpans("Our ")}</span>
                            <span className="gradient-mission">{wrapTextWithSpans("mission")}</span>
                            <span className="white-text">{wrapTextWithSpans(" is making ")}</span>
                            <span className="gradient-successful-investing">{wrapTextWithSpans("successful investing")}</span>
                            <span className="white-text">{wrapTextWithSpans(" and ")}</span>
                            <span className="gradient-trading-accessible">{wrapTextWithSpans("trading accessible")}</span>
                            <span className="white-text">{wrapTextWithSpans(" to ")}</span>
                            <span className="gradient-everyone">{wrapTextWithSpans("everyone")}</span>
                        </span>
                    </p>

                    <hr className="separator-line" />
                    {/* Paragraphs Container */}
                    <div className="paragraphs-container">
                        <p className="paragraph">
                            By combining cutting-edge technology with deep analytical insights, we provide products that simplify complex tasks, allowing you to focus on what matters most - achieving your financial objectives. Experience market dynamics with MacroGlide is the best way to realize the full potential of life!
                        </p>
                        <p className="paragraph">
                            In an industry filled with complexity and uncertainty, MacroGlide is committed to clarity and innovation. Our tools and reliable data empower traders and investors to make informed decisions and stay ahead of the market. Regardless of your experience level or goals, our solutions help you confidently navigate the financial markets.
                        </p>
                    </div>
                </div>
            </div>

            {/* App Section */}
            <div ref={appSectionRef} className="app-section">
                <div className="app-bg"></div>
                <div className="app-content">
                    <h2 className="app-heading">MACROGLIDE APP</h2>
                    <h3 className="app-subheading">Easy Way to Follow Market Trends</h3>
                    <div className="app-buttons">
                        <Link to="/app" className="app-button">Explore Our App</Link>
                        <Link to="/download" className="app-button">Download App</Link>
                    </div>
                </div>
            </div>

            {/* Indicators Section */}
            <div id="indicators" className="indicators-section" ref={indicatorsSectionRef}>
                <div className="indicators-bg"></div>
                <div className="indicators-mask"></div>
                <h1 className="indicators-title">TRADINGVIEW INDICATORS</h1>
                <div className="indicators-buttons">
                    <Link to="/indicators" className="indicators-button">Explore More</Link>
                    <Link to="/collaboration" className="indicators-button get-access-button">Get Access</Link>
                </div>
            </div>

            <div id="carousel-indicators" className="carousel-indicators-section">
                <CarouselIndicators />
            </div>

            <Research />
            <ContactUs />
            <Ft />
        </>
    );
}
