// src/Pages/Indicator6.js

import React from 'react';
import './Indicator.css';
import IndicatorImage from '../Components/L1-3 - Ind6.png';
import Logo from '../Components/HL - Our App.png'; // Reuse the same logo

const Indicator6 = () => {
  return (
    <div className="indicator-page">
      {/* OPEN-SOURCE SCRIPT Label */}
      <div className="open-source-label">
        OPEN-SOURCE SCRIPT
      </div>

      {/* Indicator Title */}
      <h1 className="indicator-title">Adaptive Smooth EMA</h1>

      {/* Publication Date */}
      <p className="publication-date">September 30, 2024</p>

      {/* Image Container with Clickable Image */}
      <div className="image-container">
        <a
          href="https://ru.tradingview.com/script/AiOxYrG9-Adaptive-Smooth-EMA-MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="image-link"
        >
          <img
            src={IndicatorImage}
            alt="Adaptive Smooth EMA Indicator"
            className="indicator-image"
          />
        </a>

        {/* TradingView Account Link */}
        <a
          href="https://ru.tradingview.com/u/MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="tradingview-account-link"
        >
          <img src={Logo} alt="MacroGlide Logo" className="logo-image" />
          <span className="logo-text">MacroGlide</span>
        </a>
      </div>

      {/* Description Section */}
      <div className="indicator-description">
        <h2>Description</h2>
        <p>
          Adaptive Smooth EMA [MacroGlide] is a powerful indicator designed to track and smooth market prices using Adaptive Exponential Moving Averages (EMAs) with dynamic phase adjustment. This tool helps traders analyze price trends and identify shifts in market momentum, making it easier to recognize potential reversals and trend continuations.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Adaptive EMA Calculation: The indicator calculates multiple EMAs with adaptive smoothing based on volatility, allowing traders to capture the market's movement more accurately. These smoothed values adjust dynamically with the market, making trend detection more precise.</li>
          <li>Dynamic Phase Adjustment: The phase of the EMA is adjusted in real-time according to the market's volatility, ensuring that the smoothing remains responsive to changes in market conditions, reducing lag and enhancing signal clarity.</li>
          <li>Customizable Color Gradients: The indicator uses color gradients to visually distinguish between uptrends and downtrends, making it easier to spot shifts in market direction. Users can customize the color scheme for better visual representation and interpretation.</li>
        </ul>

        <h2>How to Use</h2>
        <ul>
          <li>Add the indicator to your chart and adjust the EMA length and phase adjustment settings according to your trading strategy.</li>
          <li>Monitor the color shifts to quickly identify potential changes in trend direction. The transition between the uptrend and downtrend colors can signal momentum shifts.</li>
          <li>Utilize the different EMA lengths to analyze short-term and long-term trends. The smaller EMAs will react quicker to price changes, while the longer ones provide a smoother view of the overall trend.</li>
        </ul>

        <h2>Methodology</h2>
        <p>
          The Adaptive Smooth EMA indicator computes multiple EMAs with lengths ranging from 3 to 90 periods, dynamically adjusting the phase based on market volatility. This adaptive approach allows the indicator to respond effectively to both calm and volatile market conditions, providing a more accurate reflection of current trends. By smoothing the price data while maintaining responsiveness to market changes, the indicator helps traders avoid false signals and make more informed decisions.
        </p>

        <h2>Originality and Usefulness</h2>
        <p>
          Adaptive Smooth EMA [MacroGlide] stands out due to its ability to dynamically adjust to market conditions, offering an adaptive smoothing approach that reduces noise while capturing essential price movements. This makes it particularly useful for identifying trends, reversals, and optimizing entry and exit points in a trading strategy.
        </p>

        <h2>Charts</h2>
        <p>
          The indicator plots a series of smoothed EMA lines, each with a unique color gradient reflecting market sentiment. These lines help visualize price trends across different timeframes, providing a comprehensive view of the market's directional strength and momentum. The gradient color transitions further enhance the clarity of trend shifts, offering an easy-to-interpret chart for traders.
        </p>

        <h2>Enjoy the game!</h2>
      </div>

      {/* Bottom Link Button */}
      <a
        href="https://ru.tradingview.com/script/AiOxYrG9-Adaptive-Smooth-EMA-MacroGlide/"
        className="indicator-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Access on TraidingView
      </a>
    </div>
  );
};

export default Indicator6;
