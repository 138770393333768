import React, { useRef, useEffect } from 'react';
import './ContactUs.css';
import BackgroundVideo from '../Components/L1 - Anm3.mp4';
import { Link } from 'react-router-dom';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function ContactUs() {
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const videoEl = videoRef.current;
    const contentEl = contentRef.current;

    // Clear out any existing ScrollTriggers for this section
    ScrollTrigger.getAll().forEach((st) => {
      if (st.vars.trigger === sectionEl) {
        st.kill();
      }
    });

    // Ensure initial states for video and content
    gsap.set(videoEl, { filter: 'blur(0px)' });
    gsap.set(contentEl, {
      autoAlpha: 0,
      y: 50,
      scale: 0.95
    });

    // Create our timeline for blur + content reveal
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionEl,
        // Adjust start/end so the animation happens "lower in the page"
        // For example, starts after user has scrolled 25% of this section into view
        // and ends when it is -25% above the viewport (user partially left the section).
        start: "top 25%",
        end: "top -25%",
        scrub: 1.5,   // Smooth scroll ties
        // If you prefer no pin here:
        pin: false,
        // markers: true, // Uncomment for debugging
      }
    });

    // Animate the video blur from 0px to 10px
    tl.to(videoEl, {
      filter: "blur(10px)",
      ease: "none"
    }, 0);

    // Animate the content from hidden to visible
    tl.fromTo(
      contentEl,
      { autoAlpha: 0, y: 50, scale: 0.95 },
      { autoAlpha: 1, y: 0, scale: 1, duration: 1, ease: "power2.out" },
      0.1 // slight offset so blur starts first
    );

    return () => {
      tl.kill();
      ScrollTrigger.getAll().forEach((st) => {
        if (st.vars.trigger === sectionEl) {
          st.kill();
        }
      });
    };
  }, []);

  return (
    <section ref={sectionRef} className="contact-us-section">
      <div className="video-background2">
        <video
          ref={videoRef}
          className="background-video2"
          autoPlay
          loop
          muted
          playsInline
          style={{ filter: 'blur(0px)' }}
        >
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
      </div>

      <div className="overlay"></div>

      <div ref={contentRef} className="contact-content">
        <h2 className="contact-heading">CONTACT US</h2>
        <h3 className="contact-subheading">
          We are always excited to hear from our community!
        </h3>

        <div className="paragraphs2-container">
          <p className="paragraph2">
            If you have an idea for a project we could collaborate on, a suggestion for a new
            product you would like to see in our lineup, or if you have discovered a bug that needs
            fixing, please feel free to share it with us. Your ideas and feedback play a crucial
            role in helping us evolve and improve the experience we offer. They guide us in better
            understanding your needs.
          </p>
          <p className="paragraph2">
            At the heart of what we do is a commitment to meeting the needs of our users. That is why your input is so important to us. Whether it is a big idea or a small tweak, every
            message is valuable, and we make sure to take it seriously. Together, we can shape a
            better future and deliver products and services that truly make a difference. Reach out
            – we are ready to listen!
          </p>
        </div>

        <div className="button-container">
          <Link to="/collaboration" className="contact-button">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
}
