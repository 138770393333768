// src/Pages/Indicator5.js

import React from 'react';
import './Indicator.css';
import IndicatorImage from '../Components/L1-3 - Ind5.png';
import Logo from '../Components/HL - Our App.png'; // Reuse the same logo

const Indicator5 = () => {
  return (
    <div className="indicator-page">
      {/* OPEN-SOURCE SCRIPT Label */}
      <div className="open-source-label">
        OPEN-SOURCE SCRIPT
      </div>

      {/* Indicator Title */}
      <h1 className="indicator-title">EMA Volume</h1>

      {/* Publication Date */}
      <p className="publication-date">September 17, 2024</p>

      {/* Image Container with Clickable Image */}
      <div className="image-container">
        <a
          href="https://ru.tradingview.com/script/XjUCe9YR-EMA-Volume-MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="image-link"
        >
          <img
            src={IndicatorImage}
            alt="EMA Volume Indicator"
            className="indicator-image"
          />
        </a>

        {/* TradingView Account Link */}
        <a
          href="https://ru.tradingview.com/u/MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="tradingview-account-link"
        >
          <img src={Logo} alt="MacroGlide Logo" className="logo-image" />
          <span className="logo-text">MacroGlide</span>
        </a>
      </div>

      {/* Description Section */}
      <div className="indicator-description">
        <h2>Description</h2>
        <p>
          EMA Volume [MacroGlide] is a versatile tool designed to track and analyze market volumes by calculating the Exponential Moving Averages (EMAs) of total, bullish, and bearish volumes. This indicator helps traders visualize volume dynamics, identify buying and selling pressure, and make informed trading decisions based on volume activity.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Volume EMAs: The indicator calculates the EMAs of total, bullish, and bearish volumes, allowing users to observe how volume trends evolve over time. This helps identify shifts in market sentiment and potential reversals.</li>
          <li>Separation of Bullish and Bearish Volumes: By separating bullish and bearish volumes, the indicator provides a clear view of buying versus selling activity. This distinction is valuable for understanding the market's underlying momentum and direction.</li>
          <li>Customizable Visuals: Users can customize the line style and color for each volume type, allowing them to tailor the display of the indicator to their personal preferences and enhance the visual interpretation of the data.</li>
        </ul>

        <h2>How to Use</h2>
        <ul>
          <li>Add the indicator to your chart and adjust the EMA settings and display parameters according to your needs.</li>
          <li>Use the difference between bullish and bearish volumes to assess current market sentiment and analyze potential trend changes.</li>
          <li>Monitor the EMA of total volume to identify overall volume trends that can serve as additional signals for entering or exiting positions.</li>
        </ul>

        <h2>Methodology</h2>
        <p>
          The indicator calculates the EMAs for total, bullish, and bearish volumes based on the trading volumes associated with price increases or decreases. This tool helps evaluate the strength of buying and selling at different times, making it especially useful for volume and market dynamics analysis.
        </p>

        <h2>Originality and Usefulness</h2>
        <p>
          EMA Volume [MacroGlide] stands out for its ability to separate buying and selling volumes and present them in a clear visual format, significantly simplifying the analysis of market activity and decision-making in trading.
        </p>

        <h2>Charts</h2>
        <p>
          The indicator displays clean and clear charts, where each type of volume is represented by its own line and color, making visual interpretation easier. The charts focus solely on key information for analysis: EMAs of total, bullish, and bearish volumes. These features make the charts highly useful for quick analysis and trading decision-making.
        </p>

        <h2>Enjoy the game!</h2>
      </div>

      {/* Bottom Link Button */}
      <a
        href="https://ru.tradingview.com/script/XjUCe9YR-EMA-Volume-MacroGlide/"
        className="indicator-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Access on TraidingView
      </a>
    </div>
  );
};

export default Indicator5;
