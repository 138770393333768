// src/Pages/Indicator1.js

import React from 'react';
import './Indicator.css';
import IndicatorImage from '../Components/L1-3 - Ind1.png';
import Logo from '../Components/HL - Our App.png'; // Updated import for logo image

const Indicator1 = () => {
  return (
    <div className="indicator-page">
      {/* OPEN-SOURCE SCRIPT Label */}
      <div className="open-source-label">
        OPEN-SOURCE SCRIPT
      </div>

      {/* Indicator Title */}
      <h1 className="indicator-title">Bullish/Bearish Volume</h1>

      {/* Publication Date */}
      <p className="publication-date">May 7, 2024</p>

      {/* Image Container with Clickable Image */}
      <div className="image-container">
        <a
          href="https://ru.tradingview.com/script/jCVm7dQ7-Bullish-Bearish-Volume/"
          target="_blank"
          rel="noopener noreferrer"
          className="image-link" /* Added class for styling */
        >
          <img
            src={IndicatorImage}
            alt="Bullish/Bearish Volume Indicator"
            className="indicator-image"
          />
        </a>

        {/* TradingView Account Link */}
        <a
          href="https://ru.tradingview.com/u/MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="tradingview-account-link"
        >
          <img src={Logo} alt="MacroGlide Logo" className="logo-image" />
          <span className="logo-text">MacroGlide</span>
        </a>
      </div>

      {/* Description Section */}
      <div className="indicator-description">
        <h2>Description</h2>
        <p>
          The Bullish/Bearish Volume (BBV) indicator is designed to analyze buying and selling volumes based on minute-by-minute data, regardless of the selected timeframe. Sometimes the price trends in one direction, while trading volumes indicate opposing intentions among market participants. This indicator aims to identify such divergences, assisting traders in making more informed and balanced trading decisions.
        </p>

        <h2>Features</h2>
        <ul>
          <li>Calculates Buying and Selling Volumes: Uses minute-by-minute data for accurate computation of buying volumes (positive volume) and selling volumes (negative volume).</li>
          <li>Volume Aggregation: Sums up buying and selling volumes for the current time interval.</li>
          <li>Displays Volume Delta: The difference between buying and selling volumes to identify trends.</li>
          <li>Moving Average of Delta: Smooths out volume fluctuations for a better understanding of trends.</li>
          <li>Normalized Volume Volatility: Logarithmic normalization of volume volatility to identify anomalies in trading activity.</li>
          <li>Volume Mismatch Indicators: Displays arrows on the chart when volumes and prices show significant discrepancies.</li>
        </ul>

        <h2>Settings</h2>
        <ul>
          <li>Moving Average Length (Delta): Length of the moving average for volume delta (default: 14).</li>
          <li>Volatility Period (Normalization): Period for calculating volume volatility (default: 3).</li>
          <li>Volume Mismatch Percentage: Volume mismatch percentage for displaying indicators (default: 10%).</li>
        </ul>

        <h2>How to Use</h2>
        <ul>
          <li>Volume Analysis: The indicator displays green bars for buying volumes and red bars for selling volumes. Gray bars show the total trading volume.</li>
          <li>Volume Delta: The blue area shows the difference between buying and selling volumes (volume delta). The moving average of delta helps to smooth out fluctuations.</li>
          <li>Normalized Volatility: Yellow bars display normalized volume volatility, helping to identify periods of high activity.</li>
          <li>Mismatch Indicators: Red arrows above and green arrows below indicate significant discrepancies between volumes and price movements, which may signal potential trend reversals.</li>
        </ul>

        <h2>Conclusion</h2>
        <p>
          The Bullish/Bearish Volume (BBV) indicator is a powerful tool for analyzing trading volumes and identifying hidden trends. It provides valuable information to traders by breaking down market flows into understandable signals, enabling them to better recognize imbalances and make informed decisions.
        </p>
      </div>

      {/* Bottom Link Button */}
      <a
        href="https://ru.tradingview.com/script/jCVm7dQ7-Bullish-Bearish-Volume/"
        className="indicator-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Access on TraidingView
      </a>
    </div>
  );
};

export default Indicator1;
