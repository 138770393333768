import React from 'react';
import './Footer.css';
import Logo from './Logo.png';
import LinkedInLogo from './in-logo.png';
import XLogo from './x-logo.jpg';
import YouTubeLogo from './youtube-logo.png';
import InstagramLogo from './inst-logo.png';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-header">
        <h1 className="footer-question">Have any Questions?</h1>
        <h2 className="footer-contact">CONTACT US</h2>
      </div>
      <div className="footer-content">
        <div className="footer-logo-section">
          <img src={Logo} alt="MacroGlide Logo" className="footer-logo" />
          <div className="footer-logo-text">
            <h3>MacroGlide</h3>
            <p>
              Your easy way to follow <br /> market trends
            </p>
          </div>
        </div>
        <div className="footer-columns">
          <div className="footer-column">
            <h4>PRODUCTS</h4>
            <a href="/app">Our App</a>
            <a href="/indicators">Indicators</a>
            <a href="/research">Research</a>
          </div>
          <div className="footer-column">
            <h4>COMPANY</h4>
            <a href="/about">About Us</a>
            <a href="/disclaimer">Disclaimer</a>
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div className="footer-column">
            <h4>RESOURCES</h4>
            <a href="/manifesto">Manifesto</a>
            <a href="/disclaimer">Disclaimer</a>
            <a href="/terms">Terms of Use</a>
          </div>
          <div className="footer-column">
            <h4>COLLABORATION</h4>
            <a href="/contact">Contact Us</a>
            <a href="/support">Support</a>
          </div>
        </div>
        <div className="footer-socials">
          <a href="https://www.linkedin.com/company/macroglide/" target="_blank" rel="noopener noreferrer">
            <img src={LinkedInLogo} alt="LinkedIn" />
          </a>
          <a href="#">
            <img src={XLogo} alt="X" />
          </a>
          <a href="https://www.youtube.com/@MacroGlide" target="_blank" rel="noopener noreferrer">
            <img src={YouTubeLogo} alt="YouTube" />
          </a>
          <a href="https://www.instagram.com/macroglide?igsh=Nzd4cnNpdTR4Zzdq" target="_blank" rel="noopener noreferrer">
            <img src={InstagramLogo} alt="Instagram" />
          </a>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="footer-bottom">
        <p>© 2024 MacroGlide. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
